import { DayOfWeek } from '@fluentui/date-time-utilities';

/**
 * Common constants for OWA Calendar
 */
const CalendarConstants = {
    DefaultWeekStartDay: DayOfWeek.Sunday,
    DefaultWorkDays: 127, // 127 default is bit mask of 2^7,
    DefaultDaysInWorkWeek: [
        DayOfWeek.Monday,
        DayOfWeek.Tuesday,
        DayOfWeek.Wednesday,
        DayOfWeek.Thursday,
        DayOfWeek.Friday,
    ],
};

export default CalendarConstants;
