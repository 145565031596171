import { logCoreUsage } from 'owa-analytics';
import { type UTGenericAction } from 'owa-unified-telemetry';
import type { UTActionDomain } from 'owa-unified-telemetry';
import getUnifiedTelemetryName from './getUnifiedTelemetryName';

export default function clickLogger(domain: UTActionDomain, e: MouseEvent) {
    const target = e.target as Element;
    const { scope, name, idSource, origin, skipLog } = getUnifiedTelemetryName(target);
    !skipLog &&
        logCoreUsage(
            'GenericAction', // Note: this can't be a dynamic value as we can't map a dynamic event into UT EventsMapping. Check eventsMapping.ts.
            {
                domain,
                scope,
                name,
                action: 'Click',
                isAutoGenerated: true,
                idSource,
                origin,
            },
            {
                unifiedTelemetry: {
                    eventName: 'GenericAction',
                    data: {
                        ActionDomain: domain,
                        TargetScope: scope,
                        TargetName: name,
                        TargetAction: 'Click',
                        IdSource: idSource,
                        Origin: origin,
                    },
                },
            }
        );
}
