import { appStatusBetaTag, appStatusPreviewTag } from './AccAppHeader.locstring.json';
import { isFeatureEnabled } from 'owa-feature-flags';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { getIcon } from '@fluentui/style-utilities';
import loc from 'owa-localize';
import React from 'react';
import { appStatus as styles_appStatus } from './AccAppHeader.scss';
import classNames from 'owa-classnames';

import useActivityFeed from './useActivityFeed';
import {
    OwaActivityFeedButton,
    OwaFeedbackButton,
    OwaRolloutOverridesButton,
    OwaSettingsButton,
    OwaShellHelpButton,
    OwaDiagnosticsButton,
} from 'owa-suite-header/lib/components/OwaSuiteHeaderButton';
import {
    OwaActivityFeedFlexPane,
    OwaDiagnosticsFlexPane,
    OwaFeedbackFlexPane,
    OwaRolloutOverridesFlexPane,
    OwaExpressionFlexPane,
} from 'owa-suite-header/lib/components/OwaSuiteFlexPane';
import type {
    ShellControl,
    ShellLayout,
    ShellLayoutConstruct,
    NativeControlID,
    CustomHeaderButton,
    CustomShellFlexPane,
} from '@1js/suiteux-shell-react';
import { AriaRoles } from 'owa-accessibility';
import { OcvButton } from 'accelerator-ocv';
import { getApplicationSettings } from 'owa-application-settings';
import updateNotificationsBadge from './updateNotificationsBadge';
type responsivePriorities = any;

const layouts = {
    full: {
        showAppLauncher: true,
        showTenantLogo: true,
        showAppName: true,
        showMetaOS: false,
        showButtons: true,
    },
    simple: {
        showAppLauncher: false,
        showTenantLogo: false,
        showAppName: true,
        showMetaOS: false,
        showButtons: true,
    },
    compact: {
        showAppLauncher: false,
        showTenantLogo: false,
        showAppName: false,
        showMetaOS: false,
        showButtons: true,
    },
    // TO DO: once current places header is deprecated, we can remove this layout and use the 'compact' layout for showing the persona only
    persona: {
        showAppLauncher: false,
        showTenantLogo: false,
        showAppName: false,
        showMetaOS: false,
        showButtons: false,
    },
    metaos: {
        showAppLauncher: false,
        showTenantLogo: false,
        showAppName: false,
        showMetaOS: true,
        showButtons: true,
    },
};

export default function useCustomLayout(
    isAnonymous: boolean,
    isConsumerChild: boolean | undefined,
    appLogoSvg: string | undefined,
    appStatus: 'preview' | 'beta' | undefined,
    layout: keyof typeof layouts,
    showOCV = false,
    searchBar?: React.ComponentType,
    showNotifications?: boolean,
    DiagnosticPanel?: React.ComponentType<{
        onDismiss: () => void;
    }>,
    overrideFeedbackFlag?: boolean /* whether to show smiley feedback button */,
    appStatusClassName?: string,
    additionalFlexPanes?: CustomShellFlexPane[]
): ShellLayout {
    const showActivityFeed = !!showNotifications;
    const showFeedback = !isConsumerChild && overrideFeedbackFlag;
    const showFlags = isFeatureEnabled('fwk-devTools');

    useActivityFeed(showActivityFeed);

    // This ensures the font-face is registered before any icons are rendered by the suite header.
    getIcon('Emoji2');

    // By default it would show 'no issues being tracked' and we need to call lazyInitializeKnownIssuesPoll to populate it.
    // The source of issues is a database managed by framework, so for our purposes, it sounds like we don't want to load that.
    // If that changes, make the call to lazyInitializeKnownIssuesPoll like above.
    return React.useMemo(() => {
        const { showAppLauncher, showTenantLogo, showAppName, showMetaOS, showButtons } =
            layouts[layout];

        const brandingControls: ShellLayoutConstruct[] = [
            showTenantLogo && {
                id: 'tenantLogo',
                nativeControlID: 'TenantLogo' as NativeControlID,
                responsiveBehavior: {
                    responsivePriority: 1,
                    minimizeBehavior: 'hide',
                },
            },
            showAppName && {
                id: 'appLogo',
                alignSelf: 'center',
                justifyContent: showTenantLogo ? undefined : 'end',
                minWidth: showTenantLogo ? undefined : '38px',
                render: (container: HTMLDivElement) =>
                    render(<AppLogo src={appLogoSvg} />, container),
            },
            showAppName && {
                id: 'appName',
                nativeControlID: 'O365Branding' as NativeControlID,
                responsiveBehavior: {
                    responsivePriority: 9,
                    minimizeBehavior: 'hide',
                },
            },
            showAppName && {
                id: 'appStatus',
                alignSelf: 'center',
                render: (container: HTMLDivElement) =>
                    render(
                        <AppStatus appStatus={appStatus} className={appStatusClassName} />,
                        container
                    ),
                responsiveBehavior: {
                    responsivePriority: 2,
                    minimizeBehavior: 'hide',
                },
            },
        ].filter(x => !!x) as ShellLayoutConstruct[];

        // TODO: the O365 Shell provides a standard search box, using nativeControlID: 'SearchBox'.
        // We need to see how we can use that instead of providing our own, so we will have the same
        // look and feel as other Office applications, while applying our own search logic.
        // I did not see a doc for it in node_modules/@1js/suiteux-shell-react/README.md but there
        // is an ISearchShim in node_modules/@1js/suiteux-shell-react/typings/suiteux-shell-react.d.ts
        const searchControls: ShellLayoutConstruct[] = [
            {
                id: 'searchBar',
                alignSelf: 'center',
                justifyContent: 'center',
                render: (container: HTMLDivElement) => {
                    render(<AppSearchBar searchBar={searchBar} />, container);
                },
                responsiveBehavior: {
                    responsivePriority: 0,
                    minimizeBehavior: 'hide',
                    responsiveSteps: searchBar ? [150, 200, 300, 600] : undefined,
                },
            },
        ];

        const buttons: ShellLayoutConstruct[] = [];
        addItem(buttons, OwaSettingsButton, 7, !showMetaOS && showButtons);

        const activityFeedButton = OwaActivityFeedButton as CustomHeaderButton;

        if (showActivityFeed) {
            // This means that the notifications badge will be updated when the activity feed is hidden.
            activityFeedButton.headerButtonRenderData.onHide = updateNotificationsBadge;
        }

        addItem(buttons, activityFeedButton, 6, showActivityFeed && showButtons);
        addItem(
            buttons,
            OwaDiagnosticsButton,
            8,
            !!DiagnosticPanel && getApplicationSettings('Diagnostics').panel && showButtons
        );
        addItem(buttons, OwaFeedbackButton, 5, showFeedback && showButtons);
        addItem(buttons, OcvButton(), 10, showOCV && showButtons);
        addItem(buttons, OwaRolloutOverridesButton, 4, showFlags && showButtons);
        addItem(buttons, OwaShellHelpButton, 3, showButtons);

        const topLevelControls: ShellLayoutConstruct[] = [
            {
                regionID: 'brandingRegion',
                children: brandingControls,
            },
            {
                regionID: 'searchRegion',
                flex: '1 1 auto',
                justifyContent: 'center',
                children: searchControls,
            },
            {
                regionID: 'buttonsRegion',
                children: buttons,
            },
        ];

        const flexPaneCollection = [
            OwaActivityFeedFlexPane,
            OwaDiagnosticsFlexPane(DiagnosticPanel),
            OwaFeedbackFlexPane,
            OwaRolloutOverridesFlexPane,
        ];

        if (!!additionalFlexPanes) {
            flexPaneCollection.push(...additionalFlexPanes);
        }

        flexPaneCollection.push(OwaExpressionFlexPane);

        return {
            enableResponsive: true,
            leftCustomizationRegion: { includeAppLauncher: !isAnonymous && showAppLauncher },
            rightCustomizationRegion: {
                includeMeControl: !isAnonymous && !showMetaOS,
            },
            centerCustomizationRegion: {
                regionID: 'topLevelRegion',
                flex: '1 1 auto',
                children: topLevelControls,
            },
            flexPaneCollection,
        };
    }, [
        layout,
        searchBar,
        showActivityFeed,
        DiagnosticPanel,
        showFeedback,
        showOCV,
        showFlags,
        additionalFlexPanes,
        isAnonymous,
        appLogoSvg,
        appStatus,
        appStatusClassName,
    ]);
}

function addItem(
    buttons: ShellLayoutConstruct[],
    button: Partial<ShellControl>,
    responsivePriority: number,
    condition: boolean = true
) {
    if (condition) {
        buttons.push({
            ...button,
            responsiveBehavior: {
                responsivePriority,
                minimizeBehavior: 'overflow',
            },
        } as ShellControl);
    }
}

function AppLogo({ src }: { src?: string }) {
    return src ? <img src={src} role={AriaRoles.presentation} width="24px" height="24px" /> : null;
}

function AppStatus({
    appStatus,
    className,
}: {
    appStatus: 'preview' | 'beta' | undefined;
    className?: string;
}) {
    const appStatusResourceId = {
        beta: appStatusBetaTag,
        preview: appStatusPreviewTag,
    };

    return appStatus ? (
        <span className={classNames(styles_appStatus, className)}>
            {loc(appStatusResourceId[appStatus])}
        </span>
    ) : null;
}

function AppSearchBar({ searchBar: SearchBar }: { searchBar?: React.ComponentType }) {
    return SearchBar ? <SearchBar /> : null;
}
