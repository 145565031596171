import { lazyGetUnseenItemsCount } from 'owa-activity-feed';
import {
    setShellButtonCustomBadgeCount,
    setShellButtonCustomBadgeColors,
    setShellButtonCustomTooltip,
} from 'owa-suite-header-apis';
const BADGE_COLOR_RED = '#D13438';
import loc from 'owa-localize';
import { logUsage } from 'owa-analytics';
const OwaActivityFeedButtonID: string = 'owaActivityFeedButton';
import strings from './updateNotificationsBadge.locstring.json';

export default function updateNotificationsBadge() {
    // TODO - consolidate the following code with the code in OwaSuiteHeader.tsx

    (lazyGetUnseenItemsCount.importAndExecute() as Promise<number>).then(unseenItemsCount => {
        const showUnseenItemsBadge = unseenItemsCount > 0;
        if (showUnseenItemsBadge) {
            logUsage('ActivityFeed_UnreadBadgeCount', { badgeCount: unseenItemsCount });
        }
        setShellButtonCustomBadgeCount(OwaActivityFeedButtonID, unseenItemsCount);
        const bellTooltip = showUnseenItemsBadge ? loc(strings.addisonNotifNewItemsFmt) : ''; // Empty string = default tooltip (name of the pane)
        setShellButtonCustomTooltip(OwaActivityFeedButtonID, bellTooltip);

        const redBadgeColor = {
            badgeColor: BADGE_COLOR_RED,
            badgeHoverColor: BADGE_COLOR_RED,
            badgeFontColor: 'white',
            badgeFontHoverColor: 'white',
        };
        setShellButtonCustomBadgeColors(OwaActivityFeedButtonID, redBadgeColor);
    });
}
