import { getResourcePathUrl } from 'owa-resource-url';
import { observer } from 'owa-mobx-react';
import { SuiteHeader } from '@1js/suiteux-shell-react';
import { useDocumentIcon } from 'owa-react-hooks/lib/useDocumentIcon';
import { useDocumentTitle } from 'owa-react-hooks/lib/useDocumentTitle';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import loc from 'owa-localize';
import React from 'react';
import useCustomLayout from './useCustomLayout';
import useHeaderAccountInfo from './useHeaderAccountInfo';
import useNavBarData from './useNavBarData';
import useOwaThemeData from 'owa-suite-header/lib/utils/useOwaThemeData';
import useShellDataOverrides from './useShellDataOverrides';
import useSuiteHeaderLanguageProps from 'owa-suite-header/lib/utils/useSuiteHeaderLanguageProps';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { noThemeAppLogoBackground, compact, metaos } from './AccAppHeader.scss';
import { useSignOut } from './useSignOut';
import classNames from 'owa-classnames';

import type { CustomShellFlexPane, ThemeDataOverride } from '@1js/suiteux-shell-react';
import type { HeaderPaneProps } from 'owa-bootstrap';
import type { ResourceId } from 'owa-localize';

const themes = {
    neutral: {
        Primary: 'var(--colorNeutralForeground2)',
        AppName: 'var(--colorNeutralForeground2)',
        NavBar: 'var(--colorNeutralBackground2)',
        DefaultText: 'var(--colorNeutralForeground2)',
        DefaultBackground: 'var(--colorNeutralBackground2)',
        HoverText: 'var(--colorNeutralForeground2Hover)',
        HoverBackground: 'var(--colorNeutralBackground2Hover)',
        PressedText: 'var(--colorNeutralForeground2Pressed)',
        PressedBackground: 'var(--colorNeutralBackground2Pressed)',
        SelectedText: 'var(--colorNeutralForeground2Selected)',
        SelectedBackground: 'var(--colorNeutralBackground2Selected)',
    },
    lightNeutral: {
        Primary: 'var(--colorNeutralForeground1)',
        AppName: 'var(--colorNeutralForeground1)',
        NavBar: 'var(--colorNeutralBackground1)',
        DefaultText: 'var(--colorNeutralForeground1)',
        DefaultBackground: 'var(--colorNeutralBackground1)',
        HoverText: 'var(--colorNeutralForeground1Hover)',
        HoverBackground: 'var(--colorNeutralBackground1)',
        PressedText: 'var(--colorNeutralForeground1Pressed)',
        PressedBackground: 'var(--colorNeutralBackground1Pressed)',
        SelectedText: 'var(--colorNeutralForeground1Selected)',
        SelectedBackground: 'var(--colorNeutralBackground1Selected)',
    },
};

/**
 * Application Header for Accelerator applications
 *
 * This header has the following features:
 * - it shows the App Launcher and the My Account buttons, when user is authenticated.
 * - it allows the caller to pass an application name, logo and beta/preview tags.
 * - it allows a function to be called when application name is clicked.
 * - it allows for a search bar to be shown in the center.
 * - it shows help/feedback/flags/help buttons, with their respective panels.
 * - it accounts for OWA's theme, organization branding and dark mode.
 * - it has responsive design, hiding buttons in an overflow menu when window size is small.
 * - it shows the application logo and name in the browser's tab
 * - if neutralTheme is true, the header will only use neutral colors.
 *
 * Note: when starting the application, use `loadBpos: true` in the call to start() from 'owa-start'
 * in order to ensure account information and the set of applications available to the user are
 * correctly loaded and displayed by the shell.
 *
 * TODO: we should investigate using the shell's default search bar instead of passing our own.
 * TODO: showNotifications will show the bell icon and the activity panel, but some functionality
 *       in there is not yet supported. We did minimal work to bring the button/panel up as it
 *       was in Addison as of Sep-2022, but no more than that, so it should only be used in dev-mode.
 *       If we decide to go forward with it, we need to fill the gaps. In particular:
 *       - notification initialization should happen in application boot
 *       - icon must show number of unseen items
 *       - we need to properly respond to actions that show email/calendars.
 */
export default observer(function AccAppHeader({
    appId,
    appIconSvg,
    appLogoSvg,
    appNameResourceId,
    appNameString,
    appShortNameResourceId = appNameResourceId,
    appShortNameString,
    appStatus,
    searchBar,
    onAppNameClick,
    onSignOut: onSignOutProp,
    showNotifications,
    DiagnosticPanel,
    showOCV,
    showFeedback: overrideFeedbackFlag,
    theme,
    layout = 'full',
    appStatusClassName,
    additionalFlexPanes,
}: {
    appId: string;
    appIconSvg: string;
    appLogoSvg?: string;
    appNameResourceId: ResourceId;
    appNameString?: string;
    appShortNameResourceId?: ResourceId;
    appShortNameString?: string;
    appStatus?: 'preview' | 'beta';
    searchBar?: React.ComponentType;
    onAppNameClick?: () => void;
    onSignOut?: () => void;
    showNotifications?: boolean;
    DiagnosticPanel?: React.ComponentType<HeaderPaneProps>;
    showOCV?: boolean;
    showFeedback?: boolean;
    theme?: keyof typeof themes;
    layout?: 'simple' | 'compact' | 'full' | 'metaos' | 'persona';
    appStatusClassName?: string;
    additionalFlexPanes?: CustomShellFlexPane[];
}) {
    const owaThemeData = useOwaThemeData();
    const themeData = React.useMemo<ThemeDataOverride>(() => {
        if (!theme) {
            return owaThemeData;
        }

        const helpPanelLinkColorAsHex = owaThemeData.UserThemePalette.Primary;
        const UserThemePalette = { FlexPaneAccent: helpPanelLinkColorAsHex, ...themes[theme] };

        // SuiteHeader overrides FlexPaneAccent, so we need a copy of the UserThemePalette
        const TenantThemePalette = { ...UserThemePalette };

        return { ...owaThemeData, UserThemePalette, TenantThemePalette };
    }, [theme, owaThemeData]);

    const isAnonymous = !getUserConfiguration()?.SessionSettings?.UserPuid;
    const { culture, language, isRTL } = useSuiteHeaderLanguageProps();
    const isConsumerValue = isConsumer();
    const navBarData = useNavBarData(isAnonymous, themeData, appId);
    const shellAssetsContainerOverride = getResourcePathUrl('suiteux-shell');
    const shellDataOverrides = useShellDataOverrides(
        appShortNameResourceId,
        appShortNameString,
        onAppNameClick
    );

    const { isConsumerChild, userDisplayName, userID, userPrincipalName } =
        useHeaderAccountInfo(isAnonymous);

    const customLayout = useCustomLayout(
        isAnonymous,
        isConsumerChild,
        appLogoSvg,
        appStatus,
        layout,
        showOCV,
        searchBar,
        showNotifications,
        DiagnosticPanel,
        overrideFeedbackFlag,
        appStatusClassName,
        additionalFlexPanes
    );

    const className = classNames({
        [noThemeAppLogoBackground]: !theme,
        [compact]: layout == 'compact',
        [metaos]: layout == 'metaos',
    });

    useDocumentTitle(appNameString ? appNameString : loc(appNameResourceId));
    useDocumentIcon(appIconSvg, 'image/svg+xml');
    const onSignOut = useSignOut(onSignOutProp);

    return (
        <div className={className}>
            <SuiteHeader
                appBrandTheme={themeData.UserThemePalette}
                culture={culture}
                currentMainLinkElementID={appId}
                customLayout={customLayout}
                disableToasts={true}
                isConsumer={isConsumerValue}
                isRTL={isRTL}
                language={language || 'en'}
                navBarData={navBarData}
                onSignOut={onSignOut}
                shellAssetsContainerOverride={shellAssetsContainerOverride}
                shellDataOverrides={shellDataOverrides}
                themeData={themeData}
                userDisplayName={userDisplayName}
                userID={userID}
                userPrincipalName={userPrincipalName}
                workloadID="Exchange"
            />
        </div>
    );
},
'AccAppHeader');
