import { useEffect } from 'react';

/**
 * Replaces the favicon of the document while this hook is in scope.
 * This function assumes a link element rel icon has already been created.
 * @param href source of the icon; can be simply an SVG file directly imported by the caller.
 * @param type MIME type of the source; use 'image/svg+xml' when showing an SVG icon.
 */
export function useDocumentIcon(href: string, type: string) {
    useEffect(() => {
        const link = document.head.querySelector("link[rel~='icon']") as HTMLLinkElement;
        const prevType = link.type;
        const prevHref = link.href;

        link.type = type;
        link.href = href;

        return () => {
            link.type = prevType;
            link.href = prevHref;
        };
    }, [href, type]);
}
