import { lazyGovern, GovernPriority } from 'owa-tti';
import { lazyInitializeActivityFeed, lazyLoadActivityFeed } from 'owa-activity-feed';
import { lazyInitializeNotificationManager } from 'owa-notification-manager';
import React from 'react';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import updateNotificationsBadge from './updateNotificationsBadge';

export default function useActivityFeed(showActivityFeed: boolean) {
    const [shouldRefreshNotifications, setShouldRefreshNotifications] =
        React.useState<boolean>(false);

    const setNotificationsToRefresh = React.useCallback(() => {
        setShouldRefreshNotifications(true);
    }, []);

    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    React.useEffect(() => {
        if (showActivityFeed) {
            lazyInitializeNotificationManager.importAndExecute();
            lazyGovern.importAndExecute({
                task: () => lazyInitializeActivityFeed.importAndExecute(),
                priority: GovernPriority.Idle,
                name: 'lazyInitializeActivityFeed',
            });

            lazyLoadActivityFeed.importAndExecute(mailboxInfo, setNotificationsToRefresh);
        }
    }, [showActivityFeed]);

    React.useEffect(() => {
        if (showActivityFeed && shouldRefreshNotifications) {
            setShouldRefreshNotifications(false);
            updateNotificationsBadge();
        }
    }, [showActivityFeed, shouldRefreshNotifications]);
}
