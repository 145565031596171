import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceDiagnostics" */ './lazyIndex')
);

export const HybridspaceDiagnostics = createLazyComponent(
    lazyModule,
    m => m.HybridspaceDiagnostics
);

export const HybridspaceFeatureFlags = createLazyComponent(
    lazyModule,
    m => m.HybridspaceFeatureFlags
);

export const lazyOpenDiagnostics = new LazyAction(lazyModule, m => m.openDiagnostics);
export const lazyOpenFeatureFlags = new LazyAction(lazyModule, m => m.openFeatureFlags);
