/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { Panel } from '@fluentui/react/lib/Panel';
import React from 'react';
import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import {
    lazyHandleSupportPaneEvents,
    lazyGetIframeUrl as getIframeUrl,
    feedbackScenarioName,
} from 'diagnostics-and-support';
import { getApp } from 'owa-config';
import { content, scrollableContent, container } from './OcvFeedbackPanel.scss';

interface OcvFeedbackPanelProps {
    iframeUrl: string;
}

const panelStyles = {
    content,
    scrollableContent,
};

function OcvFeedbackPanel(props: OcvFeedbackPanelProps) {
    const [isOpen, setIsOpen] = React.useState(true);
    const dismissPanel = React.useCallback(() => setIsOpen(false), []);
    useWindowEvent(
        'message',
        (messageEvent: any) => {
            lazyHandleSupportPaneEvents.importAndExecute(messageEvent);
        },
        'accelerator_OcvFeedbackPanel'
    );
    return (
        <Panel isOpen={isOpen} onDismiss={dismissPanel} styles={panelStyles} isBlocking={false}>
            <iframe
                src={props.iframeUrl}
                width={'100%'}
                height={'100%'}
                frameBorder="none"
                scrolling="auto"
                className={container}
                allow="display-capture"
            />
        </Panel>
    );
}
export default function renderOcvFeedbackPanel() {
    const featureFlagsDiv = document.createElement('div');
    window.document.body.appendChild(featureFlagsDiv);
    getIframeUrl.importAndExecute(feedbackScenarioName, getApp()).then(url => {
        render(
            <ThemeProvider role={'complementary'} tabIndex={-1}>
                <OcvFeedbackPanel iframeUrl={url} />
            </ThemeProvider>,
            featureFlagsDiv
        );
    });
}
