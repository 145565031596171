import { lazySignout } from 'owa-signout';

import type { SignOutEventArgs } from '@1js/suiteux-shell-react';
import { useCallback } from 'react';

export function useSignOut(onSignOut?: () => void) {
    return useCallback(
        (eventArgs: SignOutEventArgs) => {
            eventArgs.triggerEvent.preventDefault();
            onSignOut?.();

            // We'll have to evaluate and follow up with framework team what happens when Places moves to it's own domain name and if app id impacts this.
            lazySignout.importAndExecute(location);
        },
        [onSignOut]
    );
}
