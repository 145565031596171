import type { UTActionDomain } from 'owa-unified-telemetry';
import React from 'react';
import clickLogger from './clickLogger';

/**
 * Domain for logging telemetry events for clicks on elements with data-telemetry-scope and data-telemetry-name attributes.
 * Applications that participate in GenericAction Unified Telemetry must have one and only one UnifiedTelemetryActionDomain
 * at the root of their React tree, and this will listed to events from the entire window DOM tree.
 */
export default function UnifiedTelemetryActionDomain({
    name,
    children,
}: {
    name: UTActionDomain;
    children: React.ReactElement;
}) {
    React.useEffect(() => {
        const domainClickLogger = clickLogger.bind(null, name);
        /* eslint-disable-next-line owa-custom-rules/no-deprecated-dom-events -- (https://aka.ms/OWALintWiki)
         * Baseline. DO NOT COPY AND PASTE!
         *	> 'click' should be wrapped for performance. Please use owa-event-listener instead. */
        window.addEventListener('click', domainClickLogger, { passive: true, capture: true });
        return () => {
            /* eslint-disable-next-line owa-custom-rules/no-deprecated-dom-events -- (https://aka.ms/OWALintWiki)
             * Baseline. DO NOT COPY AND PASTE!
             *	> 'click' should be wrapped for performance. Please use owa-event-listener instead. */
            window.removeEventListener('click', domainClickLogger, { capture: true });
        };
    }, [name]);

    return children;
}
