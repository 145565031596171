import classnames from 'owa-classnames';
import EmptyState from 'owa-empty-state/lib/components/EmptyState';
import { getIsDarkTheme } from 'owa-fabric-theme';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { container, detailsMessage, mainMessage } from './AccErrorPage.scss';
import { button, defaultButton } from './EventifyButton/EventifyButton.scss';
import ______resources_balloonSvg from './resources/balloon.svg';
import ______resources_genericErrorSvg from './resources/genericError.svg';
import ______resources_lockSvg from './resources/lock.svg';
import ______resources_personLock from './resources/personLock.png';
import ______resources_personLock_dark from './resources/personLock_dark.png';

import type { ResourceId } from 'owa-localize';
export interface AccErrorPageProps {
    mainMessageResourceId: ResourceId;
    detailMessageResourceId?: ResourceId;
    /** detailMessageElement is used to override detailMessageResourceId by passing in JSX elements to EmptyState */
    detailMessageElement?: (string | JSX.Element)[];
    errorIcon?: 'emptyState' | 'noPermission' | 'genericError' | 'noPermissionUpdated';
    customizedIcon?: string;
    className?: string;
    mainMessageAdditionalStyles?: string;
    clickToActionMessageResourceId?: ResourceId;
    onClickToActionClick?: () => void;
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional object properties should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    clickToActionButtonProps?: any;
    iconSizeStyle?: number;
}

const buttonStylesProps = {
    root: { backgroundColor: 'transparent' },
};

export const AccErrorPage = observer(function AccErrorPage(props: AccErrorPageProps) {
    const {
        mainMessageResourceId,
        detailMessageResourceId,
        detailMessageElement,
        errorIcon,
        customizedIcon,
        className,
        mainMessageAdditionalStyles,
        clickToActionMessageResourceId,
        onClickToActionClick,
        clickToActionButtonProps,
        iconSizeStyle,
    } = props;

    const iconSize = iconSizeStyle ? iconSizeStyle : 160;

    const classNamesProps = React.useMemo(
        () => ({
            container: classnames(className, container),
            mainMessage: classnames(mainMessage, mainMessageAdditionalStyles),
            detailsMessage,
            button: classnames(button, defaultButton),
        }),
        [className, mainMessageAdditionalStyles]
    );

    const icon = React.useMemo(() => {
        if (customizedIcon) {
            return customizedIcon;
        }
        if (errorIcon) {
            switch (errorIcon) {
                case 'emptyState':
                    return ______resources_balloonSvg;
                case 'noPermission':
                    return ______resources_lockSvg;
                case 'noPermissionUpdated':
                    return getIsDarkTheme()
                        ? ______resources_personLock_dark
                        : ______resources_personLock;
                case 'genericError':
                    return ______resources_genericErrorSvg;
            }
        }
        return undefined;
    }, [customizedIcon, errorIcon]);

    return (
        <EmptyState
            icon={icon}
            iconSize={iconSize}
            mainMessage={loc(mainMessageResourceId)}
            detailMessage={
                detailMessageElement
                    ? detailMessageElement
                    : detailMessageResourceId
                    ? loc(detailMessageResourceId)
                    : undefined
            }
            classNames={classNamesProps}
            ctaMessage={
                clickToActionMessageResourceId ? loc(clickToActionMessageResourceId) : undefined
            }
            ctaAppearance={'default'}
            onCtaClick={onClickToActionClick}
            buttonStyles={buttonStylesProps}
            ctaButtonProps={clickToActionButtonProps}
        />
    );
}, 'AccErrorPage');
