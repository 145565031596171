import { getIsDarkTheme } from 'owa-fabric-theme';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { createDarkTheme, createLightTheme } from '@fluentui/react-components';
import { FluentProvider } from '@fluentui/react-provider';

import type { BrandVariants } from '@fluentui/react-components';

// Colors for Places Brand:
// https://www.figma.com/file/SicJs4UNaTg29YS8yqhqFv/Fluent-2-design-language?type=design&node-id=53519-2914&mode=design&t=cWfqSLJxGosLE2Y4-4
const placesBrandTheme: BrandVariants = {
    10: '#050107',
    20: '#1F0F2E',
    30: '#311555',
    40: '#3D1A77',
    50: '#47209C',
    60: '#4F28C1',
    70: '#5432E6',
    80: '#5B41FF',
    90: '#6C56FF',
    100: '#7B69FF',
    110: '#8A7BFF',
    120: '#988DFF',
    130: '#A79EFF',
    140: '#B5B0FF',
    150: '#C4C1FF',
    160: '#E4E2FF',
};

const placesLightTheme = createLightTheme(placesBrandTheme);
const placesDarkTheme = createDarkTheme(placesBrandTheme);

export const PlacesFluentProvider = observer(function PlacesFluentProvider({
    className,
    children,
    style,
}: React.PropsWithChildren<{
    className?: string;
    style?: React.CSSProperties;
}>) {
    const theme = getIsDarkTheme() ? placesDarkTheme : placesLightTheme;
    return (
        <FluentProvider className={className} theme={theme} style={style}>
            {children}
        </FluentProvider>
    );
}, 'PlacesFluentProvider');
