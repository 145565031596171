import { LazyAction, LazyModule } from 'owa-bundling';
import { isFeatureEnabled } from 'owa-feature-flags';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ErrorHandler" */ './lazyIndex'),
    { name: 'ErrorHandler' }
);

const lazyInitializeErrorHandlerDevtools = new LazyAction(
    lazyModule,
    m => m.initializeErrorHandlerDevtools
);

// only initialize up once per session
let hasInitialized = false;

export async function setupErrorHandler() {
    if (
        !hasInitialized &&
        (process.env.NODE_ENV === 'dev' ||
            (isFeatureEnabled('fwk-devTools') && isFeatureEnabled('fwk-errorpopup')))
    ) {
        await lazyInitializeErrorHandlerDevtools.importAndExecute();
        hasInitialized = true;
    }
}
