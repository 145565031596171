import { useEffect } from 'react';

export function useBodyMinWidth(value: string) {
    useEffect(() => {
        const previousBodyMinWidth = document.body.style.minWidth;
        document.body.style.minWidth = value;
        return () => {
            document.body.style.minWidth = previousBodyMinWidth;
        };
    }, [value]);
}
