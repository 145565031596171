import { addDays, differenceInCalendarDays } from 'owa-datetime';
import type { DateRange } from './DateRange';

/**
 * This returns the date range which should be retained with the constraint of the provided max days
 * @param completeRange The range that we have
 * @param requestedRange The range which should be maintained as much as we can
 * @param maxDays The maximum days we can retain
 */
export default function getDateRangeToRetain(
    completeRange: DateRange,
    requestedRange: DateRange,
    maxDays: number
): DateRange {
    const daysInRequestedRange =
        differenceInCalendarDays(requestedRange.end, requestedRange.start) + 1;

    if (daysInRequestedRange === maxDays) {
        // If the requested range number of days is same as the max we can retain then we return it
        return requestedRange;
    } else if (daysInRequestedRange > maxDays) {
        // If the requested range is bigger than the max days we can retain then
        // we get the range upto max days from the start of requested range
        return {
            start: requestedRange.start,
            end: addDays(requestedRange.start, maxDays),
        };
    } else {
        // The number of days in requested range is less than max days that
        // we can retain. We will try adding more dates to it from the `completeRange`
        let otherDaysThatCanBeCached = maxDays - daysInRequestedRange;
        const requestEndToCompleteRange = differenceInCalendarDays(
            completeRange.end,
            requestedRange.end
        );
        const requestStartToCompleteRange = differenceInCalendarDays(
            requestedRange.start,
            completeRange.start
        );
        let { start, end } = requestedRange;

        if (requestEndToCompleteRange > 0) {
            // If there are some number of days between the requestedRage.end and completeRange.end
            // then add it to the range we need to retain
            const daysToAdd = Math.min(otherDaysThatCanBeCached, requestEndToCompleteRange);

            end = addDays(end, daysToAdd);
            otherDaysThatCanBeCached -= daysToAdd;
        }

        if (otherDaysThatCanBeCached > 0 && requestStartToCompleteRange > 0) {
            // If we have not exceeded the max days and there are days in between the
            // requestedRange.start and completeRange.start then add it to the range
            const daysToSub = Math.min(otherDaysThatCanBeCached, requestStartToCompleteRange);

            start = addDays(start, -1 * daysToSub);
        }

        return { start, end };
    }
}
