import { getConfig } from 'owa-service/lib/config';
import loc from 'owa-localize';
import React from 'react';
import type { SuiteHeaderOverridableProps } from '@1js/suiteux-shell-react';
import { getApplicationSettings } from 'owa-application-settings';
import type { ResourceId } from 'owa-localize';

export default function useShellDataOverrides(
    applicationNameResourceId: ResourceId,
    applicationNameString?: string,
    onApplicationNameClick?: () => void
): Partial<SuiteHeaderOverridableProps> {
    const applicationName = applicationNameString || loc(applicationNameResourceId);
    return React.useMemo(
        () => ({
            SuiteServiceUrl: `${window.location.origin}${getConfig().baseUrl}/service.svc`,
            AppHeaderLinkText: applicationName,
            AppHeaderLinkAction: onApplicationNameClick,
            UniversalMeControlEnabled: getApplicationSettings('UniversalMeControl').enabled,
        }),
        [applicationName, onApplicationNameClick]
    );
}
