import type { DateRange } from './DateRange';
import { createMonthDateRange } from './DateRange';
import type { OwaDate } from 'owa-datetime';
import { startOfWeek, addDays, isWithinRange } from 'owa-datetime';
import type { DayOfWeek } from '@fluentui/date-time-utilities';
import { DateRangeType } from '@fluentui/date-time-utilities';

/**
 * Calculate the date range of items that should be requested from the service
 */
export default function calculateRequestRange(
    selectedDate: OwaDate,
    dateRange: DateRange,
    dateRangeType: DateRangeType,
    weekStartDay: DayOfWeek
): DateRange {
    let requestRange: DateRange;

    let date = selectedDate;
    if (!isWithinRange(date, dateRange.start, dateRange.end)) {
        date = dateRange.start;
    }

    if (dateRangeType == DateRangeType.Month) {
        requestRange = createMonthDateRange(date, weekStartDay);
    } else {
        // for day, week, work week, we load from the beginning of the week to one day after the dateRange.end
        requestRange = {
            start: startOfWeek(date, weekStartDay),
            end: addDays(dateRange.end, 1),
        };
    }
    return requestRange;
}
