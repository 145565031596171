import { useEffect } from 'react';

/**
 * Replaces the document title while this hook is in scope.
 * @param title Title to show.
 */
export function useDocumentTitle(title: string | undefined) {
    useEffect(() => {
        const prevTitle = document.title;

        if (title) {
            document.title = title;
        }

        return () => {
            document.title = prevTitle;
        };
    }, [title]);
}
