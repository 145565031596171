import { getBposNavBarData } from 'owa-bpos-store';
import React from 'react';
import type { NavBarData, ThemeDataOverride } from '@1js/suiteux-shell-react';

export default function useNavBarData(
    isAnonymous: boolean,
    themeDataOverride: ThemeDataOverride,
    appId: string
): NavBarData | undefined {
    return React.useMemo(() => {
        if (isAnonymous) {
            return undefined;
        }

        const bposNavBarData = getBposNavBarData() as NavBarData;
        if (!bposNavBarData) {
            return undefined;
        }

        return {
            ...bposNavBarData,
            ThemeDataOverride: themeDataOverride,
            AppBrandTheme: themeDataOverride.UserThemePalette,
            CurrentMainLinkElementID: appId,
        };
    }, [isAnonymous, themeDataOverride, appId]);
}
