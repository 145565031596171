export function getElementOrThrow(id: string) {
    const element = document.getElementById(id);
    if (!element) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(`Element ${id} does not exist`);
    }

    return element;
}
