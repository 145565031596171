import { isRunningInMetaOSHub } from 'owa-config';
import React from 'react';
import { UNSAFE_mapRouteProperties as mapRouteProperties } from 'react-router';
import { RouterProvider } from 'react-router-dom';
import { createRouter } from '@remix-run/router';
import OwaCompatibleHistory from './OwaCompatibleHistory';

import type { RouteObject } from 'react-router-dom';
import type { Router } from '@remix-run/router';

let activeRouter: Router | undefined = undefined;

/**
 * Workaround for the fact that route loaders do not have direct access to the
 * target location state. See https://github.com/remix-run/react-router/discussions/11374
 * This allows a loader function to see which state value was passed to navigate()
 * or to Link.
 */
export function getNavigationState() {
    return activeRouter?.state?.navigation?.location?.state;
}

/**
 * Renders a RouterProvider for an OWA application using the given name and routes.
 * When running as MetaOS application, the basename is prefixed with '/hosted'.
 *
 * @param basename The base name (vdir) of the application. Must start with '/'.
 * @param routes  The set of routes supported by the application.
 * @param fallbackElement Element shown while the initial route is mounting and loading data.
 */
export default function AppRouterProvider({
    basename,
    routes,
    fallbackElement,
}: {
    basename: string;
    routes: RouteObject[];
    fallbackElement?: React.ReactNode;
}) {
    const router = React.useMemo(
        () =>
            createRouter({
                basename: (isRunningInMetaOSHub() ? '/hosted' : '') + basename,
                history: OwaCompatibleHistory, // to preserve known query string parameters
                future: { v7_prependBasename: true },
                routes,
                mapRouteProperties, // to handle errors at the correct level
            }),
        [basename, routes]
    );

    React.useEffect(() => {
        router.initialize();
        activeRouter = router;
        return () => {
            router.dispose();
            activeRouter = undefined;
        };
    }, [router]);

    return <RouterProvider router={router} fallbackElement={fallbackElement} />;
}
