import { getAccountScopeUserSettings } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function useHeaderAccountInfo(isAnonymous: boolean) {
    const userSettings = isAnonymous
        ? undefined
        : getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo());

    const sessionSettings = userSettings?.SessionSettings;

    return {
        isConsumerChild: userSettings?.IsConsumerChild,
        userDisplayName: sessionSettings?.UserDisplayName,
        userID: sessionSettings?.UserPuid,
        userPrincipalName: sessionSettings?.UserPrincipalName,
    };
}
