import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import OwaCompatibleHistory from './OwaCompatibleHistory';

/**
 * A HistoryRouter that preserves OWA application query strings.
 * NOTE: applications should use AppRouterProvider instead of this component
 *       to make use of data loader functions in routes.
 */
export default function AppHistoryRouter({
    basename,
    children,
}: React.PropsWithChildren<{
    basename: string;
}>) {
    return (
        <HistoryRouter history={OwaCompatibleHistory} basename={basename}>
            {children}
        </HistoryRouter>
    );
}
