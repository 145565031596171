const ID_ATTRIBUTE = 'data-telemetry-id';
const ID_SELECTOR = '[' + ID_ATTRIBUTE + ']';

/**
 * This function uses a simplistic method to determine the scope and name of the element that was clicked.
 * We do not try to be invasive, like reaching into innerText, to avoid logging PII or localized text.
 * Developers need to tag elements with data-telemetry-id attribute so we can use the DOM to build the hierarchy.
 * We step through closest ancentors from the click point that have data-telemetry-id populated.
 */
export default function getUnifiedTelemetryName(element: Element) {
    let name = ''; // target element name value.
    let scope = ''; // Ancestor hierarchy of tagged elements names.
    let origin = ''; // data-telemetry-origin attribute value.
    let idSource = ''; // source attribute name.
    const e: HTMLElement | null = element.closest<HTMLElement>(ID_SELECTOR);
    if (e) {
        name = e.dataset.telemetryId ?? '';
        scope = getTelemetryScope(e, ID_ATTRIBUTE, ID_SELECTOR);
        origin = e.dataset.telemetryOrigin ?? '';
        idSource = ID_ATTRIBUTE;
    }
    // Split compound name using last part for 'name' and the rest for 'scope'.
    const expanded = name.split('_');
    if (expanded.length > 1) {
        name = expanded.pop() || '';
        scope += scope ? '_' + expanded.join('_') : expanded.join('_');
    }
    return { scope, name, origin, idSource, skipLog: name.length == 0 };
}

/**
 * Walks up the DOM from the given element to find the closest ancestors with the required attribute.
 * The hierarchy is built from the closest ancestor with the attribute to the element itself.
 * The data-telemetry-parentid attribute can override the parent element for the hierarchy (flex Popover cases).
 * Returns a string that represents the scope value for the given element.
 */
function getTelemetryScope(element: HTMLElement, idAttribute: string, idSelector: string) {
    const names: string[] = [];
    // If the element has a data-telemetry-parentid attribute, use it to override the curent element.
    let cpe: HTMLElement | null = element.dataset.telemetryParentid
        ? getElementByAttribute(element, idAttribute, element.dataset.telemetryParentid) || null
        : element.parentElement?.closest<HTMLElement>(idSelector) || null;

    if (cpe) {
        for (let i = 0; i < 10; i++) {
            const idValue = cpe?.getAttribute(idAttribute);
            if (idValue && idValue.length > 0 && idValue !== names[i - 1]) {
                names.push(cleanDelimiters(idValue));
            }
            cpe = cpe.dataset.telemetryParentid
                ? getElementByAttribute(cpe, idAttribute, cpe.dataset.telemetryParentid) || null
                : cpe?.parentElement?.closest<HTMLElement>(idSelector) || null;
            if (!cpe) {
                break;
            }
        }
    }
    return names.reverse().join('_');
}

// Remove leading/trailing underscores, they should only be delimiters.
function cleanDelimiters(name: string) {
    return name?.indexOf('_') > -1 ? name.replace(/^_+|_+$/g, '') : name;
}

// Find the first element with the given attribute and value.
function getElementByAttribute(
    element: HTMLElement,
    attributeName: string,
    attributeValue: string
): HTMLElement | null {
    return (
        element?.ownerDocument.querySelector('[' + attributeName + '="' + attributeValue + '"]') ||
        null
    );
}
