import type { ExtendedDateTime } from 'owa-graph-schema';
import { asDate, owaDate, subMinutes, userDate } from 'owa-datetime';
import type { OwaDate } from 'owa-datetime';
import { getTimeZoneOffset } from 'owa-datetime-store';

const isoRegEx = /\d{4}(-\d{2}){2}T(\d{2}:){2}\d{2}/;

export default function getOwaDateFromExtendedDateTime(
    dateTime: ExtendedDateTime,
    keepInOriginalTime?: boolean
): OwaDate {
    // Although we receive an ISO timestamp from App Gateway as part of ExtendedDateTime,
    // that timestamp may not accurately reflect daylight savings time, and is mainly intended
    // to represent "wall clock time" (i.e. time in the given time zone). Rather than relying on
    // the offset value in the timestamp, we first create an OwaDate from the "wall clock" value
    // in UTC. We then use that to seed a new OwaDate in the user's time zone, and then revert the
    // UTC offset to get the correct value
    const timestamp = dateTime.dateTime.match(isoRegEx);

    if (!timestamp) {
        throw new Error('Timestamp is not in valid ISO format');
    }

    const updatedTimeUtc = owaDate(null, timestamp[0]);
    const localTime = userDate(updatedTimeUtc);

    const offset = getTimeZoneOffset(
        asDate(updatedTimeUtc),
        keepInOriginalTime ? undefined : dateTime.timeZone.name
    );

    return subMinutes(localTime, offset);
}
